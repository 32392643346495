<template>
  <div id="bigdata">
    <div class="bigdata" ref="bigdata">
      <!-- <section>
        <input type="text" placeholder="搜索解决方案" />
        <i class="el-icon-search"></i>
        <i class="el-icon-minus" style="transform: rotate(90deg);"></i>查看所有解决方案
      </section>-->
      <section>
        <aside>
          <strong>推荐</strong>
          <span
            class="title_span"
            v-for="item in NavMenu"
            :key="item.id"
            @mouseenter="enter(item.id)"
            @mouseleave="leave(item.id)"
          >{{item.name}}</span>
        </aside>
        <aside>
          <div
            v-for="item in menu"
            :key="item.id"
            @mouseenter="menu_enter(menu)"
            @mouseleave="menu_leave(menu)"
          >
            <strong style="margin-bottom:10px;">{{item.name}}</strong>
            <div class="item">
              <a
                v-for="el in item.child"
                :key="el.id"
                @click="linkTo(el)"
                class="hvr-wobble-to-bottom-right"
              >
                {{el.name}}
                <i class="el-icon-sunny"></i>
              </a>
            </div>
          </div>
        </aside>
      </section>
    </div>
  </div>
</template>

<script>
// import logo from "../../assets/images/logo.png";
export default {
  data() {
    return {
      menu: [],
      navshow: false,
    };
  },
  props: ["NavMenu"],
  methods: {
    span_style_enter(style) {
      style.width = "90%";
      style.background = "bisque";
      style.color = "black";
      style.borderRadius = "3px 0 3px 0";
    },
    span_style_leave(style) {
      style.width = "40%";
      style.background = "none";
      style.color = "white";
      style.borderRadius = "0";
    },
    linkTo(item) {
      //点击跳转
      // console.log(item);
      if (item.href === "home") {
        // console.log(item.href);
        if (
          item.bid != 9 &&
          item.bid != 10 &&
          item.bid != 11 &&
          item.bid != 13
        ) {
          localStorage.setItem("bid", item.bid);
          this.$eventbus.$emit("bid", item.bid);
          this.$router.push({path:"/bigdata/Content",query: { ID: item.bid } });
          this.$eventbus.$emit("title", "Content");

          this.$eventbus.$emit("nav_show", false);
          document.querySelectorAll("#menu_nav li").forEach((el) => {
            el.style.borderBottom = "0";
          });
          document.querySelector(
            "#menu_nav li:nth-of-type(3)"
          ).style.borderBottom = "1px solid white";
        }
      } else {
        // console.log(item.href);
        window.open(item.href);
      }
    },
    enter(id) {
      let service = this.NavMenu.filter((val) => {
        return val.id == id;
      });
      // console.log(service[0]);
      this.menu = service[0].child;
      let title_span = document.querySelectorAll(".title_span");
      let span_style = title_span[this.NavMenu.indexOf(service[0])].style;
      this.span_style_enter(span_style);
    },
    leave(id) {
      let service = this.NavMenu.filter((val) => {
        return val.id == id;
      });
      // console.log(service[0]);
      this.menu = service[0].child;
      let title_span = document.querySelectorAll(".title_span");
      let span_style = title_span[this.NavMenu.indexOf(service[0])].style;
      this.span_style_leave(span_style);
    },
    menu_enter(item) {
      // console.log(item);
      let services = this.NavMenu.filter((val) => {
        return val.child == item;
      });
      let title_span = document.querySelectorAll(".title_span");
      let span_style = title_span[this.NavMenu.indexOf(services[0])].style;
      this.span_style_enter(span_style);
    },
    menu_leave(item) {
      // console.log(item);
      let services = this.NavMenu.filter((val) => {
        return val.child == item;
      });
      let title_span = document.querySelectorAll(".title_span");
      let span_style = title_span[this.NavMenu.indexOf(services[0])].style;
      this.span_style_leave(span_style);
    },
  },
  mounted() {
    // console.log(this.NavMenu);
    this.menu = this.NavMenu[0].child;
    this.navshow = false;
    setTimeout(() => {
      this.navshow = true;
    }, 500);
  },
};
</script>
<style scoped lang="less">
@import "../../../assets/css/main.less";
#bigdata {
  width: 100%;
  height: 100%;
  //   background: blanchedalmond;
  .column;
  justify-content: center;
  .bigdata {
    width: 100%;
    height: 400%;
    // background: goldenrod;
    .column;
    align-items: flex-start;
    align-content: space-around;
    justify-content: space-around;
    color: white;
    // color: black;
    // section:nth-of-type(1) {
    //   width: 30%;
    //   height: 10%;
    //   background: rgba(204, 204, 204, 0.6);
    //   margin-left: 20px;
    //   .row;
    //   justify-content: space-around;
    //   border-radius: 0.3125rem;
    //   padding: 0 0.3125rem;
    //   input {
    //     height: 60%;
    //     border: 0;
    //     background: none;
    //     outline: none;
    //     color: white;
    //   }
    //   input::-webkit-input-placeholder {
    //     color: white;
    //   }
    //   input::-moz-placeholder {
    //     color: white;
    //   }
    //   input::-ms-input-placeholder {
    //     color: white;
    //   }
    // }
    section:nth-of-type(1) {
      width: 100%;
      height: 90%;
      //   background: gray;
      padding-left: 0.625rem;
      .row;
      justify-content: space-around;
      aside:nth-of-type(1) {
        width: 16.2%;
        height: 100%;
        // background: hotpink;
        border-right: 0.0625rem solid #ccc;
        .column;
        align-items: flex-start;
        justify-content: space-around;
        span {
          width: 45%;
          height: 2.5rem;
          padding-left: 1rem;
          transition: all ease 500ms;
          .row;
          justify-content: flex-start;
        }
      }
      aside:nth-of-type(2) {
        width: 80%;
        height: 100%;
        // background: bisque;
        .row;
        justify-content: flex-start;
        align-items: flex-start;
        div {
          margin: 0 10px;
          min-width: 10rem;
          height: 100%;
          // background: darkkhaki;
          .column;
          justify-content: flex-start;
          .item {
            width: 100%;
            height: 90%;
            // background: blueviolet;
            .column;
            justify-content: flex-start;
            a {
              width: 14rem;
              height: 3rem;
              .row;
              background: rgba(0, 0, 0, 0.58);
              color: rgba(255, 255, 255, 1);
              padding-left: 0.625rem;
              margin-top: 0.5rem;
              // border: 0.125rem solid rgba(255, 255, 255, 1);
              border-radius: 0.3125rem;
              transition: all ease 500ms;
              i {
                color: crimson;
              }
            }
            a:nth-of-type(1) {
              margin-top: 0;
            }

            a::before {
              content: "";
              width: 0.3125rem;
              height: 60%;
              border-left: 0 solid #f8b551;
              border-radius: 0.0625rem;
            }

            a:hover {
              // border: 0.125rem solid white;
              background: white;
              color: black;
            }

            a:hover::before {
              border-left-width: 0.1875rem;
            }
          }
          a {
            min-width: 1rem;
            height: 2rem;
            .row;
            justify-content: flex-start;
            margin: 15px 0;
            padding: 0 5px;
          }
          .hvr-bounce-to-bottom:before {
            content: "";
            position: absolute;
            z-index: -1;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: bisque;

            -webkit-transform: scaleY(0);
            transform: scaleY(0);
            -webkit-transform-origin: 50% 0;
            transform-origin: 50% 0;
            -webkit-transition-property: transform;
            transition-property: transform;
            -webkit-transition-duration: 0.5s;
            transition-duration: 0.5s;
            -webkit-transition-timing-function: ease-out;
            transition-timing-function: ease-out;
          }
          .hvr-bounce-to-bottom:hover,
          .hvr-bounce-to-bottom:focus,
          .hvr-bounce-to-bottom:active {
            color: black;
          }
          .hvr-bounce-to-bottom:hover:before,
          .hvr-bounce-to-bottom:focus:before,
          .hvr-bounce-to-bottom:active:before {
            -webkit-transform: scaleY(1);
            transform: scaleY(1);
            -webkit-transition-timing-function: cubic-bezier(
              0.52,
              1.64,
              0.37,
              0.66
            );
            transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
          }
        }
        div:nth-last-of-type(1) {
          margin-right: 0;
        }
        div:nth-of-type(1) {
          margin-left: 0;
        }
      }
    }
  }
}
</style>